<template>
<section class="hero">
        <div class="hero-bg" :style="{'background-image': heroImage}"></div>
        <div class="grid grid-pad">
            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <!-- Text api -->
                <div v-if="!apiNotReached" class="hero-message animated fadeInUp">
                    <h1 class="title">{{homeTitle}}</h1>
                    <div class="text" v-html="homeText"></div>
                    <div v-if="programStep === 1" class="buttons">
                        <router-link to="/product-catalog" class="btn btn-secondary">{{browseCatalog}}</router-link>
                    </div>
                </div>
                <!-- Text no api -->
                <div v-else-if="false" class="hero-message animated fadeInUp">                   
                    <div class="text">Si vous rencontrez des difficultés pour réserver sur le site, veuillez cliquer sur le lien suivant </div>
                    <div v-if="programStep === 1" class="buttons">
                        <a href="https://www.reservationsabatier.fr/#/" target="_blank" class="btn btn-secondary">Je réserve</a>
                    </div>
                </div>
                <!-- Text no api - Busy website -->
                <div v-else class="hero-message animated fadeInUp">                   
                    <div class="text">Bonjour, la réservation de vos produits LION SABATIER INTERNATIONAL est momentanément indisponible, nous vous prions de nous excuser pour la gêne occasionnée. Nous vous invitons à vous reconnecter plus tard <br></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Hero',
    computed: {
        ...mapGetters(['homeTitle', 'homeText', 'browseCatalog', 'apiNotReached', 'programStep']),
        heroImage() {
            return 'url(' + require('../assets/images/bienvenue-sur-le-site-de-reservation-de-loperation-1920-800.jpg') + ')';
        }
    }
}
</script>

<style lang="scss">
@import '@/global-styles/variables';
.hero {
    position: relative;
    
    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 75%;
        display: block;
        // background-image: url('../assets/images/bienvenue-sur-le-site-de-reservation-de-loperation-1920-800.jpg');
        background-size: cover;
        background-position: center center;
    }
}

.hero-message {
    background: $white;
    border-radius: 0.8rem;
    box-shadow: $shadow-1;
    text-align: center;
    padding: 5rem;
    margin: 30rem 0 0 0;
    z-index: 1;
    position: relative;
    
    .title {
        font-size: 3.2rem;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 2rem;
        color: $primary-color;
    }

    .text {
        h2 {
            font-size: 4.2rem;
            font-weight: 400;
            line-height: 4.2rem;
            margin-bottom: 3rem;
        }

        p {
            font-size: 1.8rem;
            margin-bottom: 2rem;

            strong {
                color: $primary-color;
            }
        }
    } 

    .buttons {
        margin-top: 2rem;
        
        .btn {
            margin: .8rem;
        }
    }
}
</style>