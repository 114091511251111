<template>
    <div id="app">
        <AppHeader />
        <router-view/>
        <AppFooter />
    </div>
</template>

<script>
import AppHeader from '@/components/navigation/AppHeader';
import AppFooter from '@/components/navigation/AppFooter';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  },
  
  created() {
    this.$store.dispatch('fetchSettings');
    this.$store.dispatch('fetchLanguages');
    this.$store.dispatch('fetchTexts');
    this.$store.dispatch('fetchProducts');

    const token = localStorage.getItem('token');

    if (token) {
      this.$store.dispatch('autoLogin', token);
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

@import "@/global-styles/mixins";
@import "@/global-styles/variables";
@import "@/global-styles/cre8ion-simplegrid";
@import "@/global-styles/flex";
@import "@/global-styles/default";
@import "@/global-styles/forms";
</style>
