import agent from '../../api/agent';

export default {
    fetchLanguages: async ({commit, getters}) => {
        try {
            const apiNotReached = getters.apiNotReached;        
            if (apiNotReached) {
                commit('SET_API_NOT_REACHED', false);
            }
            console.log(apiNotReached);
            const languages = await agent.languages.all();
            commit('SET_LANGUAGES', languages);
            commit('SET_SELECTED_LANGUAGE', languages[0].number);
        } catch (error) {
            console.log(error);
            commit('SET_API_NOT_REACHED', true);
            const apiNotReached = getters.apiNotReached;
            console.log(apiNotReached);
        }
    },

    selectLanguage: ({commit}, languageId) => {
        commit('SET_SELECTED_LANGUAGE', languageId);
    }
}