export default {
    getProduct: (state) => {
        return state.product;
    },

    getProductById: (state) => (id) => {
        return state.products.find(p => p.id === id);
    },

    getProducts: (state) => {
        return state.products;
    },

    getProductByName: (state, getters) => (name) => {
        return state.products.find(p => p[`name${getters.selectedLanguage}`] === name);
    },

    getProductsByName: (state) => {
        let filteredProducts = [];
        const names = ['Drinking cup', 'Stainless steel bottle'];
        names.forEach(name => {
            filteredProducts.push(state.products.find(p => p.name === name));
        });
        return filteredProducts;
    },

    getProductsSortedByAvailability: (state) => {
        const products = state.products;

        if (products) {
            const availableProducts = state.products.filter(p => p.inStock !== 2);
            const unavailableProducts = state.products.filter(p => p.inStock === 2);
            const sortedProducts = availableProducts.concat(unavailableProducts);
            return sortedProducts;
        }

        return state.products;
    }
}