<template>
    <main class="home">
        <LoadingSpinner v-if="isLoading" />
        <div v-else>
            <Hero />
            <section class="product-overview">
                <div v-if="programStep === 1" class="grid grid-pad">
                    <div class="flex">                    
                        <ProductOverviewCard v-for="product in products" :key="product.id" :product="product" />
                    </div>
                    <div class="col-1-1 flex justify-center">
                        <router-link class="btn btn-primary" to="/product-catalog">{{viewAllProducts}}</router-link>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>

<script>
import Hero from '@/components/Hero'
import ProductOverviewCard from '@/components/ProductOverviewCard'
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { mapGetters } from 'vuex';

export default {
    name: 'Home',
    components: { 
        Hero, 
        ProductOverviewCard, 
        LoadingSpinner 
    },
    
    computed: {
        ...mapGetters(['isLoading', 'viewAllProducts', 'metaTitle', 'programStep']),
        ...mapGetters({products: 'getProductsSortedByAvailability'})
    },

    created() {
        document.title = 'Home' + this.metaTitle;
        
        if (this.products === null) {
            this.$store.dispatch('fetchProducts');
        }

    },
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.product-overview {
    padding: 10rem 0;
}

.flex {
    margin-bottom: 4rem;
}
</style>